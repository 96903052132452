<template>
  <div>
    <b-modal
        centered
        class="multistore-modal"
        modal-class="multistore-modal"
        id="modal-1"
        v-model="visibleModal"
        v-if="currentShop"
        title="Zwiększenie limitu produktów na sklepie"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">

      <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row" :set="currentShopProductLimit = getCurrentShopProductLimit()">

            <div class="col-12 col-md-3" v-for="(productLimit, index) in shopProductLimits" :key="index">
              <div class="card enterprise-pricing text-center">
                <div class="card-body card-plan" :class="`${currentShopProductLimit && currentShopProductLimit.id === productLimit.id ? 'current-pricing-card' : 'selected-pricing-card'}`">
                  <div class="plan-title py-1">{{ productLimit.title }}</div>
                  <div class="annual-plan py-3">
                    <div class="plan-price mt-2">
                      <span class="pricing-enterprise-value fw-bolder price">${{  productLimit.priceWithTax.toFixed(2) }}</span>
                      <sub v-if="productLimit.priceWithTax > 0" class="pricing-duration font-medium-1 fw-bold text-body">/{{ timeUtil.getHumanDay(productLimit.subscriptionTimeUnit, true) }}</sub>
                    </div>
                  </div>
                  <ul class="list-group list-group-circle text-start py-2">
                    <template v-if="productLimit.description">
                      <template v-if="productLimit.description.includes('\n')">
                        <li class="list-group-item" v-for="(description, index) in productLimit.description.split('\n')" :key="index" style="padding: 0.25rem 1.2rem">{{ description }}</li>
                      </template>
                      <template v-else>
                        {{ productLimit.description }}
                      </template>
                    </template>
                  </ul>
                  <button id="enterprise_pricing_popular" class="btn my-1 waves-effect" style="width: 90%" :class="`${currentShopProductLimit && currentShopProductLimit.id === productLimit.id ? 'btn-outline-primary' : 'btn-primary'}`" @click="createOrder(productLimit.id)" :disabled="currentShopProductLimit && currentShopProductLimit.id === productLimit.id">
                    <template v-if="currentShopProductLimit && currentShopProductLimit.id === productLimit.id">
                      Obecny plan
                    </template>
                    <template v-else>
                      <i class="mdi mdi-shopping font-size-15 mr-1"></i>
                      Wybierz plan
                    </template>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </b-modal>

    <stripe-checkout
        ref="checkoutRef"
        v-if="$store.getters['stripe/publishableKey']"
        :pk="$store.getters['stripe/publishableKey']"
        :session-id="stripeId"
    />

  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import {timeUtil} from "@/helpers/time-util";

export default { // Todo: wszędzie zmienić na ten modal i zrobić jeszcze pod limit sklepów.
  components: {StripeCheckout},

  data() {
    return {
      shopProductLimits: null,

      visibleModal: false,
      currentShop: null,

      stripeId: ""
    }
  },

  computed: {
    timeUtil() {
      return timeUtil
    }
  },

  methods: {
    async loadLimits() {
      try {
        const {data} = await axios.get(`/marketplace/product/variation/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "type": "SHOP_PRODUCT_LIMIT_INCREASE",
          }
        });

        this.shopProductLimits = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    hideModal() {
      this.visibleModal = false
      this.currentShop = null
    },

    async openModal(shopId) {
      try {
        const {data} = await axios.get(`/shop/${shopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.currentShop = data
        this.visibleModal = true
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCurrentShopProductLimit() {
      if (!this.shopProductLimits || !this.currentShop) {
        return null
      }

      for (const productVariation of this.shopProductLimits) {
        if (productVariation.value === this.currentShop.productLimit) {
          return productVariation
        }
      }

      return null
    },

    createOrder(marketplaceProductVariationId) {
      const json = JSON.stringify({
        "marketplaceProductVariationId": marketplaceProductVariationId,
        "metadata": JSON.stringify({
          "shopId": this.currentShop.id
        })
      });

      axios.post(`/marketplace/order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({ data }) => {
        this.createStripeSession(data.marketplaceOrderId)
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    createStripeSession(marketplaceOrderId) {
      const json = JSON.stringify({
        "marketplaceOrderId": marketplaceOrderId
      });

      axios.post(`/stripe/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        if (result.status === 204) {
          window.location = '/dashboard/marketplace/payment_status?status=success&session_id=free'
          return
        }

        const { data } = result
        this.stripeId = data.stripeId
        this.stripeSubmit()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    stripeSubmit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    }

  },

  async created() {
    await this.loadLimits()
  }

}
</script>