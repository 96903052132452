<template>
  <div>
    <div class="button-items text-right py-2 mr-1" v-if="!hideHeader">
      <b-button @click="visualEffect = true" variant="primary" class="btn-sm">Wizualny</b-button>
      <b-button @click="visualEffect = false" variant="success" class="btn-sm">Tekstowy</b-button>
    </div>

    <div v-if="visualEffect">
      <ckeditor id="content" v-model="internalValue" :editor="editor" :config="ckEditorConfig" :class="ecatStyle"/>
    </div>
    <div v-else>
      <textarea v-model="internalValue" class="form-control" style="min-height: 250px;" />
    </div>

  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {iframeHelper} from "@/helpers/iframe-helper";

export default {
  components: {
    ckeditor: CKEditor.component
  },

  computed: {
    iframeHelper() {
      return iframeHelper
    }
  },

  props: {
    ecatStyle: {
      type: Object
    },

    hideHeader: {
      type: Boolean,
      default: false
    },

    value: {
      type: String
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.internalValue = newValue
        }
      }
    },

    internalValue: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("input", newValue);
        }
      }
    }
  },

  data() {
    return {
      editor: ClassicEditor,
      internalValue: this.value, // Store the prop value in a local data property

      ckEditorConfig: {
        heading: {
          options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
            {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
            {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
            {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
            {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
            {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'},
          ]
        }
      },

      visualEffect: true
    }
  }

}
</script>