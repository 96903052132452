<template>
  <div class="col-sm-6 card-custom-data d-flex flex-column border border-1">
    <div class="card-custom-body-header d-flex flex-row justify-content-between">
      <span class="card-custom-type h-100">
        {{ text }}
      </span>
      <span v-if="buttonMessage" class="card-custom-button">
        <a v-if="callback" class="btn btn-sm btn-link font-size-14 btn-block text-center" @click="callback">
          {{ buttonMessage }}
        </a>
      </span>
    </div>
    <div class="card-custom-title text-gray-900 font-weight-medium">
      {{ value }} <slot name="button" />
    </div>

    <div class="card-custom-title font-weight-medium">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: true
    },

    buttonMessage: {
      type: String,
      required: false
    },

    callback: {
      default: null
    }
  }
}
</script>