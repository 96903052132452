<template>
  <div>
    <b-modal
        centered
        class="multistore-modal"
        modal-class="multistore-modal"
        id="modal-1"
        v-model="visibleModal"
        v-if="currentShop"
        title="Wtyczka WordPress"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">

      <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
        <div class="row d-flex justify-content-center" :set="currentWordPressPlugin = getCurrentWordPressPlugin()">
          <div class="col-12 col-md-3" v-for="(wordPressPlugin, index) in wordPressPlugins" :key="index">
            <div class="card enterprise-pricing text-center">
              <div class="card-body card-plan"
                   :class="`${currentWordPressPlugin && currentWordPressPlugin.id === wordPressPlugin.id ? 'current-pricing-card' : 'selected-pricing-card'}`">
                <div class="plan-title py-1">{{ wordPressPlugin.title }}</div>
                <div class="annual-plan py-3">
                  <div class="plan-price mt-2">
                     <span class="pricing-enterprise-value fw-bolder price">
                       ${{ wordPressPlugin.priceWithTax.toFixed(2) }}
                     </span>
                    <sub v-if="wordPressPlugin.priceWithTax > 0" class="pricing-duration font-medium-1 fw-bold text-body">
                      /{{ timeUtil.getHumanDay(wordPressPlugin.subscriptionTimeUnit, true) }}
                    </sub>
                  </div>
                </div>
                <ul class="list-group list-group-circle text-start py-2">
                  <template v-if="wordPressPlugin.description">
                    <template v-if="wordPressPlugin.description.includes('\n')">
                      <li class="list-group-item"
                          v-for="(description, index) in wordPressPlugin.description.split('\n')" :key="index">
                        {{ description }}
                      </li>
                    </template>
                    <template v-else>
                      {{ wordPressPlugin.description }}
                    </template>
                  </template>
                </ul>
                <button id="enterprise_pricing_popular" class="btn my-1 waves-effect" style="width: 90%"
                        :class="`${currentWordPressPlugin && currentWordPressPlugin.id === wordPressPlugin.id ? 'btn-outline-primary' : 'btn-primary'}`"
                        @click="createOrder(wordPressPlugin.id)"
                        :disabled="currentWordPressPlugin && currentWordPressPlugin.id === wordPressPlugin.id">
                  {{
                    currentWordPressPlugin && currentWordPressPlugin.id === wordPressPlugin.id ? "Obecny plan" : "Wybierz plan"
                  }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-modal>

    <stripe-checkout
        ref="checkoutRef"
        v-if="$store.getters['stripe/publishableKey']"
        :pk="$store.getters['stripe/publishableKey']"
        :session-id="stripeId"
    />

  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import {timeUtil} from "@/helpers/time-util";

export default { // Todo: wszędzie zmienić na ten modal i zrobić jeszcze pod limit sklepów.
  components: {StripeCheckout},

  data() {
    return {
      wordPressPlugins: null,

      visibleModal: false,
      currentShop: null,
      currentOrderProduct: null,

      stripeId: ""
    }
  },

  computed: {
    timeUtil() {
      return timeUtil
    }
  },

  methods: {
    async loadLimits() {
      try {
        const {data} = await axios.get(`/marketplace/product/variation/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "type": "WORDPRESS_PLUGIN",
          }
        });

        this.wordPressPlugins = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    hideModal() {
      this.visibleModal = false
      this.currentShop = null
    },

    async openModal(shopId) {
      try {
        const {data} = await axios.get(`/shop/${shopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.currentShop = data
        this.visibleModal = true
        await this.loadMarketplaceOrderProduct()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceOrderProduct() {
      if (!this.currentShop.wordpressPluginMarketplaceOrderId) {
        return
      }

      try {
        const {data} = await axios.get(`/marketplace/order/product/${this.currentShop.wordpressPluginMarketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.currentOrderProduct = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCurrentWordPressPlugin() {
      if (!this.wordPressPlugins || !this.currentShop || !this.currentShop.wordpressPluginMarketplaceOrderId || !this.currentOrderProduct) {
        return null
      }

      if (this.wordPressPlugins[0].id === this.currentOrderProduct.marketplaceProductVariationId) {
        return this.wordPressPlugins[0]
      }

      return null
    },

    createOrder(marketplaceProductVariationId) {
      const json = JSON.stringify({
        "marketplaceProductVariationId": marketplaceProductVariationId,
        "metadata": JSON.stringify({
          "shopId": this.currentShop.id
        })
      });

      axios.post(`/marketplace/order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({data}) => {
        this.createStripeSession(data.marketplaceOrderId)
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    createStripeSession(marketplaceOrderId) {
      const json = JSON.stringify({
        "marketplaceOrderId": marketplaceOrderId
      });

      axios.post(`/stripe/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        if (result.status === 204) {
          window.location = '/dashboard/marketplace/payment_status?status=success&session_id=free'
          return
        }

        const { data } = result
        this.stripeId = data.stripeId
        this.stripeSubmit()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    stripeSubmit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    }

  },

  async created() {
    await this.loadLimits()
  }

}
</script>