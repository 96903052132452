export const itemsHelper = {
    itemsText,
    itemsObject
};

function itemsText(text) {
    return [{text: "ecat"}, {text: text, active: true}]
}

function itemsObject(object) {
    return [{text: "ecat"}, ...object]
}