export const iframeHelper = {
    convert
};

function convert(html) {
    if (!html) {
        return ""
    }

    html = html.replace('oembed', 'iframe width="560" height="315" title="ecat" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen');
    html = html.replace('url', 'src');
    html = html.replace('watch?v=', 'embed/');
    html = html.replace('oembed', 'iframe');
    return html
}