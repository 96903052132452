<template>
  <Layout>
    <PageHeader :items="itemsHelper.itemsText($t('user-shops.title'))" :title="$t('user-shops.title')"/>
    <multistore-modal ref="multiStoreRef"/>

    <div class="row">
      <div class="col-12">
        <div class="card-body px-0">
          <div class="p-3 shop-header rounded col-sm-12">
            <div class="row">
              <div class="col-xl-4">
                <div class="pb-2 font-weight-bold">
                  Akcje
                </div>
                <b-button class="w-lg px-5" variant="success" @click="$refs.createShopModal.openModal(null)">
                  {{ $t('user-shops.create-shop.title') }}
                </b-button>
              </div>
              <div class="col-xl-4">
                <div class="d-flex justify-content-center">
                  Limit sklepów:&nbsp;<div class="text-primary font-weight-bold">Multistore {{
                    $store.getters["storelimits/getCurrentMultiStore"] ? $store.getters["storelimits/getCurrentMultiStore"].title : 'Darmowy'
                  }}
                </div>
                </div>

                <div class="d-flex justify-content-center" v-if="$store.getters['storelimits/getCurrentMultiStoreSubscription']">
                  Wygasa:&nbsp;<div class="text-primary font-weight-bold">{{  marketplaceOrderHelper.getExpireTime($store.getters["storelimits/getCurrentMultiStoreSubscription"]) }}</div>
                </div>

                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-gray-900 font-weight-bold font-size-34">{{
                      table.totalRows
                    }}/{{
                      $store.getters["storelimits/getCurrentMultiStore"] ? $store.getters["storelimits/getCurrentMultiStore"].value : '1'
                    }}
                  </div>

                  <b-button class="w-md px-4 mx-2" variant="primary" @click="$refs.multiStoreRef.openModal()">
                    <i class="mdi mdi-plus mr-2"/>
                    Zwiększ limit
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="py-2">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('table.show') }}&nbsp;
                    <b-form-select
                        v-model="table.perPage"
                        :options="table.pageOptions"
                        size="sm"
                    />
                    &nbsp;{{ $t('table.entries') }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('table.search') }}
                    <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2"
                                  type="search"
                                  @keyup.enter="setToFirstPageAndRefresh"
                                  @input="setToFirstPageAndRefresh"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-12 col-md-6">
              </div>

              <div class="col-sm-12 col-md-6 d-inline-flex justify-content-end">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
                  </ul>
                </div>
                <div class="align-items-center">
                  <label class="d-inline-flex align-items-center mx-2 mb-0">
                    <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>
                  </label>
                  <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                </div>
              </div>
            </div>

            <template v-if="loading">
              <div class="text-center">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
              </div>
            </template>
            <template v-else>
              <div class="row my-3">
                <div v-for="(shop, index) in shops" :key="index" class="col-xl-6">
                  <shop-card
                      :industries="industries"
                      :shop="shop"
                      :user="getUser(shop.userId)"
                      :wordPressPlugins="wordpressPlugins"
                      :productLimits="productLimits"
                      class="shop-card"
                      @open-shop-product-limit-modal="$refs.shopProductLimitRef.openModal"
                      @edit-shop-modal="$refs.createShopModal.openModal"
                      @buy-wordpress-plugin-modal="$refs.shopWordpressRef.openModal"
                      @delete-shop-modal="$refs.deleteShopModal.openModal(shop, () => loadShops())"
                      @edit-shop-element-modal="$refs.editShopElementModal.openModal"
                      @open-shop-create-category-modal="$refs.shopCreateCategoryModal.openModal(shop)"
                  />
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                {{
                  $t('table.entries-footer', {
                    'amount': paginationHelper.getElementsAmount(table),
                    'elements': paginationHelper.getElementsCount(table),
                    'all': table.totalRows
                  })
                }}
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded">
                    <b-pagination v-model="table.currentPage" :per-page="table.perPage" :total-rows="table.rows" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <shop-product-limit-modal ref="shopProductLimitRef"/>
    <shop-wordpress-modal ref="shopWordpressRef"/>
    <create-shop-modal ref="createShopModal"/>
    <delete-shop-modal ref="deleteShopModal"/>
    <edit-shop-element-modal ref="editShopElementModal" @update-shop="updateShop"/>
    <shop-create-category-modal ref="shopCreateCategoryModal"/>

  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";

import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {timeUtil} from "@/helpers/time-util";
import CreateShopModal from "@/components/shop/create-shop-modal.vue";
import ShopProductLimitModal from "@/components/shop-product-limit-modal.vue";
import ShopWordpressModal from "@/components/shop-wordpress-modal.vue";
import {itemsHelper} from "@/helpers/items-helper";
import {industryHelper} from "@/helpers/industry-helper";
import ShopCard from "@/components/shop/shop-card.vue";
import DeleteShopModal from "@/components/shop/delete-shop-modal.vue";
import EditShopElementModal from "@/components/shop/edit-shop-element-modal.vue";
import axios from "axios";
import {errorCatcher} from "../../../helpers/error-catcher";
import MultistoreModal from "../../../components/multistore-modal.vue";
import shopCreateCategoryModal from "../../../components/shop/category/shop-create-category-modal.vue";
import {marketplaceOrderHelper} from "../../../helpers/marketplace-order-helper";

export default {

  components: {
    MultistoreModal,
    EditShopElementModal,
    ShopCard,
    ShopWordpressModal,
    ShopProductLimitModal,
    CreateShopModal,
    DeleteShopModal,
    shopCreateCategoryModal,
    Layout,
    PageHeader
  },

  watch: {
    "table.currentPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadShops();
        }
      },
    },

    "table.perPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadShops();
        }
      },
    }
  },

  data() {
    return {
      loading: false,
      submitted: false,
      shops: [],
      wordpressPlugins: new Map(),
      productLimits: new Map(),
      users: null,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: ["name", "domain", "email_address"],
        sortBy: "name",
        sortDesc: false,
        inputPage: ""
      },

      industries: null
    };
  },

  methods: {
    async setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      await this.loadShops()
    },

    async updateShop(shop) {
      const index = this.shops.findIndex(element => element.id === shop.id);

      if (index !== -1) {
        this.shops[index] = shop
        this.shops = [...this.shops]
        await this.loadIndustries()
      }
    },

    getUser(userId) {
      return this.users.get(userId) || null
    },

    async loadShops() {
      this.loading = true

      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/shop/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            forUser: true,
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            languageId: this.$store.getters["translation/getCurrentLocale"]
          }
        });

        this.shops = data
        await this.loadShopsCount()
        await this.loadUsers()
        await this.loadIndustries()
        await this.loadWordPressPlugins()
        await this.loadProductLimits()
      } catch (error) {
        this.shops = []
      }

      this.loading = false
    },

    async loadShopsCount() {
      try {
        const {data} = await axios.get(`/shop/count`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            forUser: true,
            filter: this.table.filter,
            languageId: this.$store.getters["translation/getCurrentLocale"]
          }
        });

        this.table.totalRows = data;
        this.table.rows = data;
      } catch (error) {
        this.table.totalRows = 0
        this.table.rows = 0
      }
    },

    async loadIndustries() {
      const ids = [];
      for (let shop of this.shops) {
        if (shop.industryId) {
          ids.push(shop.industryId);
        }
      }

      this.industries = await industryHelper.loadByIds(ids)
    },

    async loadWordPressPlugins() {
      if (this.shops.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (let shop of this.shops) {
        if (shop.wordpressPluginMarketplaceOrderId) {
          marketplaceOrderIds.push(shop.wordpressPluginMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.wordpressPlugins = new Map(data.map((obj) => [obj.shopId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductLimits() {
      if (this.shops.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (let shop of this.shops) {
        if (shop.productLimitMarketplaceOrderId) {
          marketplaceOrderIds.push(shop.productLimitMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.productLimits = new Map(data.map((obj) => [obj.shopId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadUsers() {
      if (this.shops.length === 0) {
        return;
      }

      const ids = [];
      for (let shop of this.shops) {
        ids.push(shop.userId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const {data} = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(data.map((obj) => [obj.id, obj]));
    }
  },

  computed: {
    marketplaceOrderHelper() {
      return marketplaceOrderHelper
    },
    itemsHelper() {
      return itemsHelper
    },

    timeUtil() {
      return timeUtil
    },

    dateUtil() {
      return dateUtil
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  mounted() {
    this.$root.$on('user-shops-table-reload', async () => await this.loadShops())
  },

  beforeDestroy() {
    this.$root.$off("user-shops-table-reload");
  },

  async created() {
    await this.loadShops()
  }

};
</script>

<style>
.shop-card {
  height: 100%;
}
</style>