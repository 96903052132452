<template>
  <b-modal
      v-if="shop"
      v-model="visibleModal"
      size="xl"
      :title="$t(category.id ? 'categories.edit.title' : 'categories.create.title')"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <form>

      <div class="form-group">
        <label for="name">Wyświetlana Nazwa</label>
        <input id="name" v-model="category.name" type="text" class="form-control" :class="{ 'is-invalid': $v.category.name.$error }" />
        <div v-if="!$v.category.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <ecat-multiselect
            :set-value="category.parentId"
            @change="value => category.parentId = value"
            view-id="name"
            save-id="id"
            :can-unselect="true"
            label="Wybór nadrzędnej kategorii"
            placeholder="Wybierz kategorię"
            :fetch-one-url="`/shop/${shop.id}/category`"
            :load-url="`/shop/${shop.id}/category/list/pagination`"
            :query-url="`/shop/${shop.id}/category/by/name`"
            param="name"
        />
      </div>

      <div class="form-group">
        <label>Wyświetlana Uproszczona Nazwa URL</label>
        <input type="text" v-model="category.displaySlug" class="form-control" :class="{ 'is-invalid': $v.category.displaySlug.$error }" />
        <div v-if="!$v.category.displaySlug.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Wyświetlany Opis</label>
        <ecat-ckeditor v-model="category.displayDescription" />
      </div>

      <div class="form-group">
        <label>Miniaturka</label>

        <template v-if="category.thumbnailUrl">
          <p>Obecnie ustawiony obrazek:</p>
          <img class="avatar-lg align-self-start rounded mr-3" :src="category.thumbnailUrl" alt/>
        </template>

        <div class="button-items">
          <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="upload-thumbnail" @change="uploadThumbnail" hidden/>
          <label for="upload-thumbnail" class="btn btn-primary">{{ $t('message.send') }}</label>
        </div>

        <template v-if="thumbnailImage">
          <p>Nowy obrazek:</p>
          <img class="avatar-lg align-self-start rounded mr-3" :src="thumbnailImage" alt/>
        </template>

        <p class="mb-0 font-size-12">{{ $t('profile.allowed-file-types')}} </p>
      </div>

      <div class="text-center">
        <template v-if="sending">
          <b-button variant="secondary" class="btn-sm" style="width: 200px; height: 40px;"><span aria-hidden="true" class="spinner-border text-center text-success"/></b-button>
        </template>
        <template v-else>
          <b-button @click="createOrEditCategory" variant="success">{{ $t(category.id ? 'categories.edit.button' : 'categories.create.button') }}</b-button>
        </template>

        <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import EcatCkeditor from "@/components/ecat-ckeditor.vue";
import {imageHelper} from "@/helpers/image-helper";
import axios from "axios";
import {errorCatcher} from "../../../helpers/error-catcher";

export default {
  name: "shop-create-category-modal",
  components: {
    EcatCkeditor
  },

  data() {
    return {
      visibleModal: false,
      shop: null,
      category: {},
      thumbnailImage: "",
      thumbnailExtension: "",
      thumbnailFile: null,
      callback: null,
      sending: false
    }
  },

  validations: {

    category: {
      name: { required },
      displaySlug: { required }
    }

  },

  methods: {
    openModal(shop, category = {}, callback = null) {
      this.shop = shop
      this.category = category
      this.callback = callback
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.shop = null
      this.category = {}
      this.thumbnailImage = ""
      this.thumbnailExtension = ""
      this.thumbnailFile = null
      this.callback = null
      this.$v.category.$reset()
    },

    async createOrEditCategory() {
      this.$v.category.$touch();
      if (this.$v.category.$invalid) {
        return;
      }

      this.sending = true
      if (this.thumbnailFile) {
        try {
          await this.upload()
        } catch (ignored) {
          this.sending = false
          return
        }
      }

      const json = JSON.stringify({
        "id": this.category.id,
        "name": this.category.name,
        "displayName": this.category.name,
        "parentId": this.category.parentId,
        "displaySlug": this.category.displaySlug,
        "displayDescription": this.category.displayDescription,
        "thumbnailUrl": this.category.thumbnailUrl
      });

      try {
        await axios.put(`/shop/${this.shop.id}/category`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.$bvToast.toast(this.$t(this.category.id ? "categories.edit.success" : "categories.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.hideModal()
        if (this.callback) {
          this.callback()
        }
      } catch (error) {
        if (errorCatcher.isCustomError(error)) {
          this.hideModal()
        }

        errorCatcher.catchErrors(error)
        this.submitted = false;
      }

      this.sending = false
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.thumbnailFile);
      formData.append('extension', this.thumbnailExtension)

      try {
        const { data } = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.category.thumbnailUrl = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },


    uploadThumbnail(input) {
      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {file, dataUrl, extension} = result
        this.thumbnailExtension = extension
        this.thumbnailImage = dataUrl;
        this.thumbnailFile = file
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    }
  }

}
</script>