<template>
  <b-modal
      v-if="shop"
      v-model="visibleModal"
      :title="`Potwierdzenie usunięcia sklepu: ${shop.name}`"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <form>
      <div class="form-group">
        <label>Kod bezpieczeństwa</label>
        <input v-model="form.verificationCode" type="number" class="form-control" />
      </div>

      <p class="text-danger">Kod bezpieczeństwa potrzebny do usunięcia sklepu został wysłany na adres E-mail</p>

      <div class="text-right">
        <b-button @click="deleteShop" variant="success">Potwierdź</b-button>
        <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "../../helpers/error-catcher";
import {swalHelper} from "../../helpers/swal-helper";

export default {

  data() {
    return {
      visibleModal: false,
      shop: null,
      callback: null,

      form: {
        verificationCode: ""
      }
    }
  },

  methods: {

    openModal(shop, callback = null) {
      this.callback = callback

      axios.post(`/shop/delete-verification/create/${shop.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Kod bezpieczeństwa potrzebny do usunięcia sklepu został wysłany na adres E-mail", "success").then(() => {
          this.shop = shop
          this.visibleModal = true
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    hideModal() {
      this.visibleModal = false
      this.shop = null
    },

    deleteShop() {
      swalHelper.yesOrNo(() => {
        const json = JSON.stringify({
          shopId: this.shop.id,
          code: this.form.verificationCode
        })

        axios.post(`/shop/delete-verification/verify`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(() => {
          Swal.fire("Sukces!", "Sklep został usunięty", "success").then(() => {
            if (this.callback) {
              this.callback()
            }
          });
          this.hideModal()
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      });
    }

  }

}
</script>